export const SAMPLE_DATA = [
  {
    annotations: {
      metrics: {
        sample: {
          friendlyName: 'Sample Data',
        },
      },
    },
    data: [
      { timestamp: '2024-05-15T00:00:00.000Z', sample: 10 },
      { timestamp: '2024-05-16T00:00:00.000Z', sample: 20 },
      { timestamp: '2024-05-17T00:00:00.000Z', sample: 30 },
      { timestamp: '2024-05-18T00:00:00.000Z', sample: 40 },
      { timestamp: '2024-05-19T00:00:00.000Z', sample: 50 },
      { timestamp: '2024-05-20T00:00:00.000Z', sample: 60 },
      { timestamp: '2024-05-21T00:00:00.000Z', sample: 70 },
      { timestamp: '2024-05-22T00:00:00.000Z', sample: 80 },
      { timestamp: '2024-05-23T00:00:00.000Z', sample: 90 },
      { timestamp: '2024-05-24T00:00:00.000Z', sample: 100 },
    ],
  },
];

/**
 * Extract the data that comes from the runQueries
 * @param {*} data the data coming from the runQueries
 * @param {*} source the source specified as target
 */
export function extractDataFromSource(data, source) {
  try {
    let dataFound = data?.runQueries?.find(
      query => query.id.toString() === source.dataSource.queryId.toString()
    ).result;
    if (dataFound) {
      return JSON.parse(dataFound);
    } else return {};
  } catch (exception) {
    console.log(exception);
  }
}

/**
 * Returns true/false if the queries aren't empty
 * @param {*} queries
 */
export function hasQueries(queries) {
  return queries !== undefined && queries?.length > 0;
}

export function hasData(data) {
  return data && data.flatMap(d => d.data).length > 0;
}

export const extractDatasets = data => {
  const dataset = [];
  (data || []).forEach(d => {
    const metaMetrics = Object.keys(d?.metadata?.metrics || d?.annotations?.metrics || {});
    const metaDimensions = Object.keys(d?.metadata?.dimensions || d?.annotations?.dimensions || {});

    const dimensions = [];
    const dimensionValues = [];

    const formattedData = d.data.map(row => {
      const newRow = {};
      newRow.date = row.timestamp;

      metaMetrics.forEach(metric => {
        const translatedDimension = metaDimensions
          .map(dimension => row[dimension])
          .filter(d => d !== null)
          .join(' | ');
        let translatedMetric = (d.metadata || d.annotations).metrics[metric].friendlyName;

        if (translatedDimension.length > 0) {
          translatedMetric = translatedMetric + (translatedDimension.length > 0 ? ' | ' + translatedDimension : '');
        }

        if (dimensions.indexOf(translatedMetric) < 0) {
          dimensions.push(translatedMetric);
        }

        if (!dimensionValues.find(dv => dv.name === translatedMetric)) {
          dimensionValues.push({
            name: translatedMetric,
            friendlyName: translatedMetric,
          });
        }

        newRow[translatedMetric] = row[metric];
      });
      return newRow;
    });

    dimensions.map(dimension => {
      dataset.push({
        queryId: d.id,
        dimensions: [
          { name: 'date', friendlyName: 'Date' },
          {
            name: dimension,
            friendlyName: dimension,
          },
        ],
        dimensionValues,
        source: formattedData
          .map(row => ({
            date: row.date,
            [dimension]: row[dimension] || null,
          }))
          .filter(row => row[dimension] !== null),
      });
    });
  });
  return dataset;
};

export const formatDataForDataTable = data => {
  if (!Array.isArray(data) || !data.length) return [];

  // Extract headers from all queries
  const allHeaders = data.reduce((headers, query) => {
    if (query.data?.[0]) {
      const queryHeaders = query.data[0].filter(header => header !== 'date');
      return [...headers, ...queryHeaders];
    }
    return headers;
  }, []);

  // Create a map of date to row data
  const dateMap = new Map();

  // Process each query's data
  data.forEach(query => {
    if (!query.data || query.data.length < 2) return;

    const headers = query.data[0];
    const dateIndex = headers.indexOf('date');

    if (dateIndex === -1) return;

    // Process each row of data
    for (let i = 1; i < query.data.length; i++) {
      const row = query.data[i];
      const date = row[dateIndex];

      if (!dateMap.has(date)) {
        // Initialize new row with null values for all headers
        const newRow = {
          date,
          ...Object.fromEntries(allHeaders.map(header => [header, null])),
        };
        dateMap.set(date, newRow);
      }

      // Add values from current query to the row
      const currentRow = dateMap.get(date);
      headers.forEach((header, index) => {
        if (header !== 'date') {
          currentRow[header] = row[index];
        }
      });
    }
  });

  // Convert the map to an array and sort by date
  const mergedData = Array.from(dateMap.values()).sort((a, b) => new Date(a.date) - new Date(b.date));

  return [
    {
      queryId: 'merged',
      dimensions: [
        { name: 'date', friendlyName: 'Date' },
        ...allHeaders.map(header => ({
          name: header,
          friendlyName: header,
        })),
      ],
      source: [['date', ...allHeaders], ...mergedData.map(row => [row.date, ...allHeaders.map(header => row[header])])],
    },
  ];
};

export const extractMetricsAndDimensions = data => {
  const metaInformation = data?.metadata || data?.annotations;
  const metrics = Object.keys(metaInformation?.metrics || {}).map(m => metaInformation?.metrics[m].friendlyName);

  const dimensions = Object.keys(metaInformation?.dimensions || {}).map(
    d => metaInformation.dimensions[d].friendlyName
  );

  return { metrics, dimensions };
};

export const extractQueryDescription = metricsAndDimensions => {
  const { metrics, dimensions } = metricsAndDimensions;
  const queryDescription =
    'Show me ' + metrics.join(', ') + (dimensions.length === 0 ? '' : ' Broken By ' + dimensions.join(', '));
  return queryDescription;
};
