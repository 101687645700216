import React, { useEffect, useRef, useMemo, memo } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { ErrorComponent } from '../../ui';
import { extractDatasets, SAMPLE_DATA } from '../../../utils/DataUtils';
import { WidgetSkeleton, SkeletonTypes } from '../widgetSkeleton';
import ChartsNoDataAvailable from './ChartsNoDataAvailable';
import { useWidgetQueriesData } from '../../../api/useWidgetQueries';
import { PickaxeEchartsTheme } from './PickaxeEchartsTheme';
import { getFormatter, supportedFormats } from '../../../../../common/src/utils/FormattingUtils';
import { addDays } from 'date-fns';
import useWidgetBuilderStore from '../../../stores/useWidgetBuilderStore';

echarts.registerTheme('pickaxe', PickaxeEchartsTheme);

const EchartsChart = ({ id, type, className, children, component, queries = [], options, parentId }) => {
  const {
    boardConfig,
    selectedElementId,
    updateWidgetElement,
    setCurrentElementConfig,
    dateRangeByWidget,
    dateRangeGlobal,
  } = useWidgetBuilderStore(state => state);

  const dateRange = dateRangeGlobal || dateRangeByWidget[parentId] || { from: addDays(new Date(), -7), to: new Date() };

  const echartsRef = useRef(null);

  let { isLoading, isError, data } = useWidgetQueriesData(component, queries, dateRange);

  const datasets = useMemo(() => {
    const datasets = [...extractDatasets(data || SAMPLE_DATA)];
    return datasets;
  }, [data]);

  const createDefaultOptions = () => {
    const defaultOptions = {};

    if (datasets === undefined) {
      return null;
    }

    const mergedOptions = {
      ...defaultOptions,
      ...options,
      dataset: datasets,
    };

    if (mergedOptions?.series?.length > 0) {
      mergedOptions.series = mergedOptions.series.map(serie => {
        let tooltip = {};
        if (typeof serie?.tooltip?.valueFormatter === 'string') {
          tooltip = {
            ...serie?.tooltip,
            valueFormatter: value => getFormatter(serie?.tooltip?.valueFormatter, value),
          };
        }
        return { ...serie, tooltip };
      });
    }

    return mergedOptions;
  };

  const chartOptions = useMemo(() => createDefaultOptions(), [options]);

  useEffect(() => {
    const handleResize = () => echartsRef.current?.getEchartsInstance().resize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const isEditMode = window.location.href.includes('/builder/');
    if (isEditMode && !isLoading && !isError && selectedElementId === id) {
      setCurrentElementConfig({
        id: selectedElementId,
        component,
        className,
        type,
        props: {
          options: { ...chartOptions },
        },
      });
    }
  }, [updateWidgetElement, isLoading, isError, selectedElementId, boardConfig, chartOptions]);

  if (isLoading) return <WidgetSkeleton type={SkeletonTypes.chart} />;
  if (isError) return <ErrorComponent error={errors.join(', ')} />;

  if (chartOptions === null) return null;

  if (!chartOptions.dataset.flatMap(d => d.source).length === 0) return <ChartsNoDataAvailable />;

  ['yAxis', 'xAxis'].forEach(axi => {
    if (Array.isArray(chartOptions[axi])) {
      chartOptions[axi] = chartOptions[axi] = chartOptions[axi].map(axiVal => {
        if (supportedFormats.indexOf(axiVal.formatType) > -1) {
          axiVal.axisLabel = {
            ...axiVal.axisLabel,
            formatter: value =>
              getFormatter(
                axiVal.formatType,
                value,
                axiVal.formatMask?.trim()?.length === 0 ? null : { format: axiVal.formatMask }
              ),
          };

          axiVal.axisPointer = {
            ...axiVal?.axisPointer,
            label: {
              ...axiVal?.axisPointer?.label,
              formatter: axiValue => {
                return getFormatter(
                  axiVal.formatType,
                  axiValue.value,
                  axiVal.formatMask?.trim()?.length === 0 ? null : { format: axiVal.formatMask }
                );
              },
            },
          };
        }
        return {
          ...axiVal,
        };
      });
    }
  });

  return (
    <div id={id} draggable={true} type={type} className={className}>
      <ReactECharts
        ref={echartsRef}
        option={chartOptions}
        style={{ height: '400px', width: '100%' }}
        notMerge={true}
        lazyUpdate={true}
        theme="pickaxe"
      />
      {children}
    </div>
  );
};

export { EchartsChart };
