import { format } from 'date-fns';

function formatNumber(value, currency, locale = 'en-US', multiplierFactor = 1, defaultSufix = '') {
  if (typeof value !== 'number') {
    throw new Error('Value must be a number');
  }

  const suffixes = [
    { value: 1e6, symbol: 'mi' },
    { value: 1e3, symbol: 'k' },
  ];

  let formattedValue = value * multiplierFactor;
  let suffix = '';

  for (const { value: threshold, symbol } of suffixes) {
    if (Math.abs(value) >= threshold) {
      formattedValue = value / threshold;
      suffix = symbol;
      break;
    }
  }

  let intlFormat = {
    minimumFractionDigits: suffix ? 1 : 0, // Show decimal for abbreviated numbers
    maximumFractionDigits: suffix ? 1 : 0,
  };

  if (currency) {
    intlFormat = {
      ...intlFormat,
      style: 'currency',
      currency,
    };
  }

  // Format the base number with Intl.NumberFormat
  const formatter = new Intl.NumberFormat(locale, intlFormat);

  return formatter.format(formattedValue) + suffix + defaultSufix;
}

export const supportedFormats = ['Number', 'Currency', 'Date', 'Percentage'];

export const getFormatter = (type, value, formatOptions) => {
  switch (type) {
    case 'Number':
      return formatNumber(value);
    case 'Percentage':
      return formatNumber(value, undefined, undefined, 100, '%');
    case 'Date':
      return format(new Date(value), formatOptions?.format || 'MM/dd');
    case 'Currency':
      return formatNumber(value, 'USD');

    default:
      return null;
  }
};
