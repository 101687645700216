import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@widget-builder/src/components/ui';
import { PlusCircle } from 'lucide-react';

const FallbackPage = () => {
  return (
    <div className="container mx-auto p-4 flex items-center justify-center min-h-[calc(100vh-100px)]">
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-sm">
        <h1 className="mb-2 text-2xl font-bold font-mslab_700">No Projects Available</h1>
        <p className="mb-6 text-gray-600">We couldn't find any active projects or boards for your account.</p>

        <Button asChild className="w-full">
          <Link to="/settings/configuration/project/new" className="flex items-center justify-center">
            <PlusCircle className="w-4 h-4 mr-2" />
            Create Your First Project
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default FallbackPage;
