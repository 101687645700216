import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getWidgetQueries } from '../../../dashboard/src/api/WidgetApi';
import { SAMPLE_DATA } from '../utils/DataUtils';
import { widgetCreate, widgetRead, widgetUpdate, widgetDelete, widgetMovePosition } from './widgetBuilderApi';
import { format } from 'date-fns';
import { useEffect } from 'react';
import useGlobalStore from '../../../dashboard/src/stores/globalStore';

export const useReadWidget = (widgetID, onSuccess) => {
  const tenantID = +window.localStorage.getItem('defaultTenantID');

  return useQuery({
    queryKey: ['widget', tenantID, widgetID],
    queryFn: () => widgetRead({ tenantID, id: +widgetID }),
    onSuccess,
    enabled: !!tenantID && !!widgetID, // Only run if both tenantID and widgetID are provided
  });
};

export const useUpdateWidget = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ widgetID, input }) => {
      const tenantID = window.localStorage.getItem('defaultTenantID');
      const { id, createdAt, ...validInput } = input;

      return widgetUpdate({ tenantID, widgetID, input: validInput });
    },
    onSuccess: (data, variables) => {
      const { widgetID } = variables;
      const tenantID = +window.localStorage.getItem('defaultTenantID');

      queryClient.invalidateQueries({ queryKey: ['widget', tenantID, widgetID] });
    },
    onError: error => {
      console.error('Error updating widget:', error);
    },
  });
};

export const useCreateWidget = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ boardConfigID, input }) => {
      const tenantID = +window.localStorage.getItem('defaultTenantID');
      return widgetCreate({ boardConfigID, tenantID, input });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: 'widget-config' });
    },
    onError: error => {
      console.error('Error creating widget:', error);
    },
  });
};

export const useDeleteWidget = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: widgetID => {
      const tenantID = +window.localStorage.getItem('defaultTenantID');
      return widgetDelete({ tenantID, widgetID });
    },
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: 'widget-config' });
      queryClient.invalidateQueries(['dashboard-config']);
    },
    onError: error => {
      console.error('Error deleting widget:', error);
    },
  });
};

export const useMoveWidgetPosition = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ widgetID, position }) => {
      const tenantID = +window.localStorage.getItem('defaultTenantID');
      return widgetMovePosition({ tenantID, widgetID, position });
    },
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: 'widget-config' });
      queryClient.invalidateQueries(['dashboard-config']);
    },
    onError: error => {
      console.error('Error deleting widget:', error);
    },
  });
};

/**
 * Prepares the QB filter based on
 * @param {*} filters
 * @returns
 */
const buildFilter = filters => {
  return {
    type: 'or',
    fields: [
      {
        type: 'and',
        fields: filters,
      },
    ],
  };
};

/**
 * Shareable function used to retrieve queries data
 * @param {*} queries
 * @returns
 */
export const getQueryData = async (queries, dateRange, filters) => {
  try {
    let formattedFilters = null;
    let hasFiltersAppied = false;
    if (filters) {
      const filterEntries = Object.entries(filters);
      hasFiltersAppied = filterEntries.flatMap(([dimension, dimensionValues]) => dimensionValues).length > 0;
      formattedFilters = filterEntries.map(([dimension, dimensionValues]) => {
        return {
          type: 'and',
          fields: [
            {
              type: 'in',
              dimension: dimension,
              values: dimensionValues.map(dv => dv.label),
            },
          ],
        };
      });
    }
    const response = await getWidgetQueries(
      {
        queries: queries.map(q => {
          const parsedQuery = JSON.parse(q.query);
          if (dateRange) {
            parsedQuery.startDate = format(dateRange.from, 'yyyy-MM-dd');
            parsedQuery.endDate = format(dateRange.to, 'yyyy-MM-dd');
            parsedQuery.granularity = 'day';
          }

          if (formattedFilters && hasFiltersAppied) {
            parsedQuery.filter = buildFilter(formattedFilters);
          }
          return {
            ...q,
            query: JSON.stringify(parsedQuery),
          };
        }),
      },
      true
    );

    return response.runQueries
      ? response.runQueries.flatMap(resultData => ({
          id: resultData.id,
          ...JSON.parse(resultData.result),
        }))
      : [];
  } catch (e) {
    console.error('Failed to fetch or parse data:', e);
    return SAMPLE_DATA;
  }
};

/**
 * Hook used to retrieve data from the widget queries
 * @param {*} component
 * @param {*} queries
 * @returns
 */
export const useWidgetQueriesData = (component, queries, dateRange) => {
  const { currentBoardObj } = useGlobalStore(state => state);

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: [
      component,
      queries.map(q => q.id),
      // we neeed to take care while caching queries which the date may vary.. in that case, a new
      // cache key should be created with the date range.
      !dateRange ? '' : format(dateRange.from, 'yyyy-MM-dd') + format(dateRange.to, 'yyyy-MM-dd'),
      currentBoardObj?.selectedDimensionValues,
    ],
    queryFn: async () => await getQueryData(queries, dateRange, currentBoardObj?.selectedDimensionValues),
    retry: false,
    enabled: queries.length > 0,
  });

  useEffect(() => {
    refetch();
  }, [dateRange]);

  if (queries.length === 0) return SAMPLE_DATA;

  return { isLoading, isError, data, refetch };
};

export const useGetFetchQuery = name => {
  const queryClient = useQueryClient();

  return queryClient.getQueryData(name);
};
