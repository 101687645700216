import { Skeleton } from '@widget-builder/src/components/ui';

function MetricSkeleton() {
  return (
    <div className="space-y-4">
      <div className="flex items-end space-x-4">
        <Skeleton className="w-32 h-16" />
        <Skeleton className="w-20 h-8" />
      </div>
      <Skeleton className="w-full h-24" />
    </div>
  );
}

function ChartSkeleton() {
  return (
    <div className="space-y-4">
      <div className="flex justify-between h-48">
        {Array.from({ length: 8 }).map((_, i) => (
          <Skeleton key={i} className="w-12 h-full" />
        ))}
      </div>
      <div className="flex justify-between">
        {Array.from({ length: 8 }).map((_, i) => (
          <Skeleton key={i} className="w-12 h-4" />
        ))}
      </div>
    </div>
  );
}

function TableSkeleton() {
  return (
    <div className="space-y-4">
      <Skeleton className="w-full h-10" />
      {Array.from({ length: 5 }).map((_, i) => (
        <Skeleton key={i} className="w-full h-8" />
      ))}
    </div>
  );
}

function WidgetSkeleton({ type = 'metric' }) {
  const WidgetContentSkeleton = () => {
    switch (type) {
      case 'metric':
        return <MetricSkeleton />;
      case 'chart':
        return <ChartSkeleton />;
      case 'table':
        return <TableSkeleton />;
      default:
        return <Skeleton className="w-full h-48" />;
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-sm">
      <div className="flex items-center justify-between mb-6">
        <Skeleton className="h-6 w-[160px]" />
        <Skeleton className="h-6 w-[100px]" />
      </div>
      <WidgetContentSkeleton />
    </div>
  );
}

export function DashboardSkeleton() {
  const widgetTypes = ['metric', 'chart', 'table', 'metric', 'chart', 'metric'];

  return (
    <div className="container relative max-w-screen-xl px-5 py-2 mx-auto bg-white">
      {/* Dashboard header */}
      <div className="flex items-center justify-between mb-8">
        <Skeleton className="h-8 w-[200px]" />
        <div className="flex space-x-4">
          <Skeleton className="h-10 w-[120px]" />
          <Skeleton className="h-10 w-[120px]" />
        </div>
      </div>

      {/* Widget grid */}
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {widgetTypes.map((type, index) => (
          <WidgetSkeleton key={index} type={type} />
        ))}
      </div>
    </div>
  );
}
